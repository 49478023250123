<template>
  <div>
    <a-row :gutter="24">
      <a-col :md="24">
        <a-card :bordered="false">
          <!-- 查询区域 -->
          <div class="table-page-search-top">
            <div class="table-page-search-wrapper table-page-search-header">
              <!-- 搜索区域 -->
              <a-form layout="inline" @keyup.enter.native="searchQuery">
                <a-row :gutter="24">
                  <a-col :span="6">
                    <a-form-item
                      label="账户地址"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="账户地址"
                        v-model="queryParam.address"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <!-- <a-col :span="6">
                    <a-form-item
                      label="APIKEY"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="APIKEY"
                        v-model="queryParam.apikey"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col> -->
                  <a-col :span="6">
                    <a-form-item
                      label="策略ID"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="策略ID"
                        v-model="queryParam.strategy_id"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col>


                 
                  <a-col :span="6">
                    <a-form-item
                      label="交易方向"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-select
                        v-model="queryParam.side"
                        allowClear
                        @change="searchQuery"
                        placeholder="交易方向"
                      >
                        <a-select-option value="BUY">BUY</a-select-option>
                        <a-select-option value="SELL">SELL</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-item
                      label="合约交易对"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="合约"
                        v-model="queryParam.symbol"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-item
                      label="状态"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="状态"
                        v-model="queryParam.status"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <!-- <a-col :span="8">
                    <a-form-item
                      label="时间筛选"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 10, offset: 1 }"
                    >
                      <a-range-picker
                        :value-format="dateFormat"
                        v-model="completeTime"
                        :ranges="ranges"
                        :open="endOpen"
                        @openChange="handleEndOpenChange"
                        @change="handleChange"
                      >
                      </a-range-picker>
                    </a-form-item>
                  </a-col> -->
                </a-row>
              </a-form>
            </div>
            <div class="table-page-search">
              <div class="button-onditions">
                <a-button icon="search" type="primary" @click="searchQuery"
                  >查詢</a-button
                >
                <a-button style="margin-top: 10px;" icon="sync" type="primary" @click="searchReset">重置</a-button>
              </div>
            </div>
          </div>
          <!-- 操作按钮区域 -->
          <div class="table-operator" style="margin-top: 5px">
            <!-- <a-popover placement="topLeft">
              <template slot="content">
                <p>由於數據量龐大，為提升數據處理效率，僅支持導出5萬條數據</p>
              </template>
              <a-button
                type="primary"
                icon="download"
                @click="handleExportXls('交易清單')"
                :loading="exportLoading"
                id="export"
                >導出
                <a-icon type="question-circle" />
              </a-button>
            </a-popover> -->
            <!-- <a-button
              type="primary"
              icon="download"
              @click="handleExportXls('交易清單')"
              :loading="exportLoading"
              id="export"
              >導出
            </a-button> -->
          </div>
          <!-- table区域-begin -->
          <div>
            <a-table
              ref="table"
              size="middle"
              bordered
              rowKey="id"
              :columns="columns"
              :dataSource="dataSource"
              :pagination="ipagination"
              :loading="loading"
              @change="handleTableChange"
              class="table_info"
              :scroll="{ x: scrollX }"
            >
              <!-- <span slot="action" slot-scope="text, record">
                    <a-tag
                      color="#87d068"
                      v-if="btnEnableList.indexOf(1) > -1"
                      @click="handleEdit(record)"
                      >编辑</a-tag
                    >
                    <a-popconfirm
                      v-if="btnEnableList.indexOf(1) > -1"
                      title="确定删除吗?"
                      @confirm="() => handleDelete(record.id)"
                    >
                      <a-tag color="#f50">删除</a-tag>
                    </a-popconfirm>
                  </span> -->
            </a-table>
          </div>
          <!-- table区域-end -->
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { JeecgListMixin } from "@/mixins/JeecgListMixin";
import { AdapterTableMixin } from "@/mixins/AdapterTableMixin";
import { downFile, postAction } from "@/api/manage";
import moment from "moment";

export default {
  name: "orderlyList",
  mixins: [JeecgListMixin, AdapterTableMixin],
  components: {},
  data() {
    return {
      // 查询条件
      queryParam: {},
      // 员工
      staffList: [],
      // 表头
      columns: [
        {
          title: "#",
          dataIndex: "",
          width: 40,
          align: "center",
          customRender(t, r, index) {
            return parseInt(index) + 1;
          },
        },
        {
          title: "账户地址",
          align: "center",
          dataIndex: "address",
        },

        // {
        //   title: "APIKEY",
        //   align: "center",
        //   dataIndex: "apikey",
        // },

        {
          title: "策略ID",
          align: "center",
          dataIndex: "strategy_id",
        },
        {
          title: "功能",
          align: "center",
          dataIndex: "function",
          customRender: function (t, r, index) {
            if (t == "mark_market") {
              return <a-tag color="rgb(50 184 111)">做市</a-tag>;
            } else {
              return <a-tag color="rgb(50 184 111)">量化</a-tag>;
            }
          },
        },
        {
          title: "合约",
          align: "center",
          dataIndex: "symbol",
          
        },
        // {
        //   title: "挂单类型",
        //   align: "center",
        //   dataIndex: "type",
        // },
      

        {
          title: "交易方向",
          align: "center",
          dataIndex: "side",
          width: 100,
          customRender: function (t, r, index) {
            if (t == "BUY") {
              return <a-tag color="#f50">BUY</a-tag>;
            } else {
              return <a-tag color="#87d068">SELL</a-tag>;
            }
          },
        },
        {
          title: "挂单数量",
          align: "center",
          dataIndex: "quantity",
        },
        {
          title: "挂单价格",
          align: "center",
          dataIndex: "price",
        },
        {
          title: "指数价格",
          align: "center",
          dataIndex: "index_price",
        },
        


        {
          title: "下单时间",
          align: "center",
          dataIndex: "insertion_time",
        },
        // {
        //   title: "内部订单号",
        //   align: "center",
        //   dataIndex: "new_client_order_id",
        // },
        {
          title: "外部订单",
          align: "center",
          dataIndex: "order_id",
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "status",
        },
        // {
        //   title: "回合批次",
        //   align: "center",
        //   dataIndex: "batch_number",
        // },
        // {
        //   title: "买一",
        //   align: "center",
        //   dataIndex: "bid_price",
        // },
        // {
        //   title: "卖一",
        //   align: "center",
        //   dataIndex: "ask_price",
        // },

        // {
        //   title: "操作",
        //   dataIndex: "action",
        //   align: "center",
        //   scopedSlots: { customRender: "action" },
        // },
      ],
      url: {
        list: "/orderlyTrade/page",
        exportXlsUrl: "/orderlyTrade/export",
      },
      dateFormat: "YYYY-MM-DD",
      //  默认七天内
      completeTime: [
        moment().subtract(6, "days").startOf("day"),
        moment().endOf("day"),
      ],
      exportLoading: false,
      ranges: {
        本周: [
          moment().startOf("week"),
          moment().endOf("week").subtract(0, "days"),
        ],
        上周: [
          moment().startOf("week").subtract(7, "days"),
          moment().endOf("week").subtract(7, "days"),
        ],
        七天内: [
          moment().subtract(6, "days").startOf("day"),
          moment().endOf("day"),
        ],
      },

      endOpen: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    // 监听日期选择器的change事件
    handleChange(dateArray) {
      if (dateArray && dateArray.length > 0) {
        let startDate = new Date(dateArray[0]);
        let endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6); // 根据第一个日期计算出7天后的日期
        let dateTime = moment(dateArray[1]).format("YYYY-MM-DD");
        if (!!dateTime) {
          dateTime = new Date(dateTime).getTime();
        }
        let endDateTime = moment(endDate).format("YYYY-MM-DD");
        if (!!endDateTime) {
          endDateTime = new Date(endDateTime).getTime();
        }
        if (dateTime > endDateTime) {
          this.completeTime = [
            dateArray[0],
            moment(endDate).format("YYYY-MM-DD"),
          ];
        }
      }
    },
    handleEdit(record) {
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "编辑";
      if (this.btnEnableList.indexOf(1) === -1) {
        this.$refs.modalForm.isReadOnly = true;
      }
    },
    handleExportXls(fileName, url) {
      if (this.exportLoading) {
        this.$message.warning("其他文件正在导出，请稍后再试！");
        return false;
      }
      let exportXlsUrl;
      if (url) {
        exportXlsUrl = url;
      } else {
        exportXlsUrl = this.url.exportXlsUrl;
      }

      this.exportLoading = true;
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = { ...this.queryParam };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param["ids"] = this.selectedRowKeys.join(",");
      }
      downFile(exportXlsUrl, param).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          this.exportLoading = false;
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(
            new Blob([data], { type: "application/vnd.ms-excel" }),
            fileName + ".xls"
          );
          this.exportLoading = false;
        } else {
          let url = window.URL.createObjectURL(
            new Blob([data], { type: "application/vnd.ms-excel" })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
          this.exportLoading = false;
        }
      });
    },
  },
  watch: {
    completeTime: {
      handler(val, oldVal) {
        if (val.length > 0) {
          let start = new Date(val[0] + " 00:00:00").getTime();
          let end = new Date(val[1] + " 23:59:59").getTime();
          this.$set(this.queryParam, "startTime", start);
          this.$set(this.queryParam, "endTime", end);
        }
        this.searchQuery();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
@import "~@assets/less/common.less";
@import "~@assets/less/button.less";
@import "~@assets/less/TaskProcess.less";
.table_info ::v-deep .ant-table-tbody > tr > td {
  padding: 5px 8px !important;
  overflow-wrap: break-word;
  font-weight: 500;
}
.table-page-search-top {
  height: 100px;
}
</style>
